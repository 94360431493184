import { useEffect, useState } from 'react'
import { Box, Flex, HStack, VStack, useBreakpointValue } from '@chakra-ui/react'
import { ContentstackArticleCard as ContentstackArticleCardProps } from '@Types/contentstack'
import { fontFamilies } from 'composable/chakra/figma-tokens'
import { ContentstackArticleCard } from 'composable/components/contentstack/article-card'
import { calculateFontStyles } from 'composable/components/contentstack/utils'
import { UiContainer } from 'composable/components/ui-container'
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5'
import { Carousel, CarouselIconButton, CarouselSlide, useCarousel } from '../carousel'

export interface ArticleCardSliderProps {
  displayImages?: boolean
  items: Array<ContentstackArticleCardProps>
  containerMarginTop?: string | number
  containerMarginBottom?: string | number
  containerSize?: string
  containerAlignment?: string
  containerEyebrow?: string
  containerTitle?: string
  containerDescription?: string
  headingAlignment?: string
  titleFontFamily?: string
  titleFontSize?: string | number
  analyticsTrackingData?: string
}

const CarouselNavigationIcons = ({ slider }) => (
  <Box position="absolute" width="100%" top="calc(50% - 54px)" zIndex={2}>
    <Flex justifyContent="space-between" hideBelow={'sm'} width="100%" margin={0}>
      <CarouselIconButton
        pos="absolute"
        left={3}
        width="48px"
        height="48px"
        background="shading.100"
        borderRadius={1}
        onClick={() => slider.current?.prev()}
        icon={<IoChevronBackOutline />}
        aria-label="Previous Slide"
        margin={0}
        tabIndex={0}
        zIndex={2}
      />
      <CarouselIconButton
        pos="absolute"
        right={3}
        width="48px"
        height="48px"
        background="shading.100"
        borderRadius={1}
        onClick={() => slider.current?.next()}
        icon={<IoChevronForwardOutline />}
        aria-label="Next Slide"
        margin={0}
        tabIndex={0}
        zIndex={2}
      />
    </Flex>
  </Box>
)

interface CarouselNavigationDotsProps {
  currentSlide: number
  slider: any
  items: Array<ContentstackArticleCardProps>
}

const CarouselNavigationDots = ({ slider, items, currentSlide }: CarouselNavigationDotsProps) => (
  <HStack position="relative" width="full" justify="center" display={'flex'} gap={0}>
    {items.map(({ uid }, idx) => (
      <Box
        flexBasis={'100%'}
        w="0"
        tabIndex={0}
        cursor="pointer"
        key={uid}
        height="var(--chakra-space-0-5,2px)"
        bg={currentSlide === idx ? 'text' : 'shading.300'}
        onClick={() => slider.current?.moveToIdx(idx)}
        onKeyDown={() => slider.current?.moveToIdx(idx)}
        aria-label={`Go to slide ${idx + 1}`}
      />
    ))}
  </HStack>
)

const ArticleCardSliderTastic = ({
  items,
  containerMarginBottom = 0,
  containerMarginTop = 0,
  containerSize = 'full',
  displayImages = true,
  containerEyebrow,
  containerTitle,
  containerDescription,
  headingAlignment,
  titleFontFamily,
  titleFontSize,
  analyticsTrackingData,
}: ArticleCardSliderProps) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [showSlider, setShowSlider] = useState(false)
  const [ref, slider] = useCarousel({
    slideChanged: (slider) => setCurrentSlide(slider.track.details.rel),
    destroyed: () => setCurrentSlide(0),
    loop: true,
    mode: 'free',
    ...slidersBreakpoints[displayImages.toString()],
  })

  const isMobile = useBreakpointValue({ base: true, lg: false })
  const alignment: any = headingAlignment || 'center'
  const fontSize: any = titleFontSize || 400

  const {
    fontSize: _titleFontSize,
    fontWeight: titleFontWeight,
    lineHeight: titleLineHeight,
  } = calculateFontStyles(fontSize)

  useEffect(() => {
    const isSlidable = items?.length > (isMobile ? 1 : 5)
    setShowSlider(isSlidable)
  }, [isMobile, items])

  const textAlignment = setTextAlign(alignment)

  if (!items?.length) {
    return null
  }

  return (
    <UiContainer
      marginBottom={containerMarginBottom}
      marginTop={containerMarginTop}
      mx="auto"
      py={{ base: 4, md: 6 }}
      px={{ base: 2, lg: 6 }}
      position="relative"
      size={containerSize}
    >
      <VStack width="100%" textAlign={isMobile ? 'center' : textAlignment} gap={3} position="relative">
        {containerEyebrow && (
          <Box width="100%" fontFamily={fontFamilies.primary} textStyle={'eyebrow-75'}>
            {containerEyebrow}
          </Box>
        )}
        {containerTitle && (
          <Box
            width="100%"
            fontFamily={titleFontFamily || 'Libre Franklin'}
            fontSize={_titleFontSize}
            lineHeight={titleLineHeight}
            fontWeight={titleFontWeight}
          >
            {containerTitle}
          </Box>
        )}
        {containerDescription && (
          <Box width="100%" fontFamily={fontFamilies.primary} textStyle={'body-300'}>
            {containerDescription}
          </Box>
        )}
      </VStack>
      <Carousel my={5} ref={ref}>
        {showSlider && <CarouselNavigationIcons slider={slider} />}
        {items.map((item, index) => (
          <CarouselSlide key={item?.uid} aria-label={item.article_card_title ?? `ArticleCard slide ${index + 1}`}>
            <ContentstackArticleCard
              {...item}
              analyticsTrackingData={analyticsTrackingData}
              relativeIndex={index + 1}
            />
          </CarouselSlide>
        ))}
      </Carousel>
      {showSlider && <CarouselNavigationDots currentSlide={currentSlide} items={items} slider={slider} />}
    </UiContainer>
  )
}

const slidersBreakpoints = {
  true: {
    slides: {
      origin: 0,
      perView: 2,
      spacing: 16,
    },
    breakpoints: {
      '(min-width: 40em)': {
        slides: {
          origin: 0,
          perView: 4,
          spacing: 16,
        },
      },
      '(min-width: 62em)': {
        slides: {
          origin: 0,
          perView: 5,
          spacing: 16,
        },
      },
      '(min-width: 80em)': {
        slides: {
          origin: 0,
          perView: 6,
          spacing: 16,
        },
      },
    },
  },
  false: {
    slides: {
      origin: 0,
      perView: 2,
      spacing: 16,
    },
    breakpoints: {
      '(min-width: 40em)': {
        slides: {
          origin: 0,
          perView: 4,
          spacing: 16,
        },
      },
      '(min-width: 62em)': {
        slides: {
          origin: 0,
          perView: 5,
          spacing: 16,
        },
      },
      '(min-width: 80em)': {
        slides: {
          origin: 0,
          perView: 5.5,
          spacing: 16,
        },
      },
    },
  },
}

const setTextAlign = (alignment: string) => {
  switch (alignment) {
    case 'left':
      return 'start'
    case 'right':
      return 'end'
    default:
      return 'center'
  }
}

export default ArticleCardSliderTastic
