import React, { useEffect, useState } from 'react'
import { Box, Skeleton } from '@chakra-ui/react'
import {
  ContentstackArticleCard,
  ContentstackArticleCardSlider as ContentstackArticleCardSliderProps,
} from '@Types/contentstack'
import ArticleCardSliderTastic from 'frontastic/tastics/composable/article-card-slider'
import useContentstack from '../hooks/useContentstack'

export const ContentstackArticleCardSlider = ({
  article_item: items,
  container_margin_bottom,
  container_margin_top,
  container_width,
  text_align: container_alignment,
  container_eyebrow,
  container_title,
  container_description,
  heading_alignment,
  title_font_family,
  title_font_size,
  analyticsTrackingData,
}: ContentstackArticleCardSliderProps) => {
  const [contentList, setContentList] = useState<ContentstackArticleCard[]>()
  const { getEntryByUid, isLoading } = useContentstack()

  useEffect(() => {
    const getContent = async () => {
      try {
        const itemsResults = items?.map((item) => getEntryByUid('component_article_card', item.uid))
        const results = (await Promise.all(itemsResults)) as unknown as ContentstackArticleCard[]
        setContentList(results)
      } catch (error) {
        console.error('Error processing Article Card Slider data', error)
      }
    }

    getContent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading || !contentList?.length) return <Skeleton />

  return (
    <Box>
      <Skeleton isLoaded={!(isLoading || !contentList?.length)}>
        <ArticleCardSliderTastic
          items={contentList}
          containerMarginBottom={container_margin_bottom}
          containerMarginTop={container_margin_top}
          containerSize={container_width}
          containerAlignment={container_alignment}
          containerEyebrow={container_eyebrow}
          containerDescription={container_description}
          containerTitle={container_title}
          headingAlignment={heading_alignment}
          titleFontFamily={title_font_family}
          titleFontSize={title_font_size}
          analyticsTrackingData={analyticsTrackingData}
        />
      </Skeleton>
    </Box>
  )
}
