import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  Center,
  Divider,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  Image,
  useDisclosure,
} from '@chakra-ui/react'
import { BAEntry } from '../hooks/useContentstack'
import { extractPath } from 'composable/helpers/utils/extract-url'
import { promoClickUtagLinkEvent } from 'helpers/tealiumHelper'

export interface BannerAnnouncementProps {
  children: BAEntry[]
}

export const BannerAnnouncement = ({ items, analyticsTrackingData, monetateBanner, text_color, content_alignment }) => {
  const { isOpen, onClose } = useDisclosure()
  const [activeIndex, setActiveIndex] = useState(0)
  const [onOpen, setOpen] = useState(false)
  const [timer, setTimer] = useState(3000)
  const [hover, setHover] = useState(false)

  useEffect(() => {
    if (hover) return

    setTimeout(() => {
      if (timer <= 0) {
        setTimer(3000)
        setActiveIndex((prevIndex) => (prevIndex + 1) % items?.length)
      } else setTimer(timer - 100)
    }, 100)
  }, [timer, hover])

  const handleClick = function (e) {
    promoClickUtagLinkEvent(getPromoName(e.target?.href, '1'))

    if (e.target.innerText == 'View Details') {
      setOpen(true)
    } else {
      setOpen(false)
      setHover(false)
    }
  }

  const getPromoName = (href, relativeIndex) => {
    const analyticsLocationIdentifier = extractPath(href ?? '')
    const dataPromotionName =
      analyticsTrackingData && `${analyticsTrackingData} | ${relativeIndex} | ${analyticsLocationIdentifier}`

    return dataPromotionName
  }

  const handleFocus = () => {
    setHover(true)
  }

  const handleBlur = () => {
    setHover(false)
  }

  return monetateBanner ? (
    <Box id="mt_slideshow" mx={{ base: -4, md: 0 }}></Box>
  ) : (
    <Box
      id="contentstack-container"
      color={text_color.hex}
      display={'flex'}
      justifyContent={content_alignment ? content_alignment : 'center'}
      alignItems={'center'}
      pl={4}
      pr={6}
    >
      {items?.map((item, index) => (
        <Box
          key={index}
          overflow="hidden"
          display={{ base: activeIndex === index ? 'block' : 'none', md: activeIndex === index ? 'flex' : 'none' }}
          transition="'display ease 0.5s'"
          onMouseOver={() => {
            setHover(true)
          }}
          onMouseLeave={() => {
            setHover(false)
          }}
          alignSelf={'center'}
          justifyContent={'center'}
          alignItems={'center'}
          textAlign={'center'}
          px={{ base: 0, md: 5 }}
          aria-label={`Banner ${index + 1}`}
          tabIndex={0}
        >
          <Text tabIndex={0} display={{ base: 'inline', sm: 'inline', md: 'inherit' }}>
            {item.text}
          </Text>
          {item.link_1 && (
            <Link
              aria-label={`Promotion link: ${item.link_1.title}`}
              _hover={{ color: '#ffffff' }}
              px={1}
              onClick={handleClick}
              fontWeight={600}
              href={item?.link_1.href == '/' || item?.link_1.href == undefined ? '#' : item?.link_1.href}
              textDecoration={'underline'}
              whiteSpace={'nowrap'}
              tabIndex={0}
              onFocus={handleFocus}
              onBlur={handleBlur}
              target={
                item?.link_1.href == '/' || item?.link_1.href == undefined || item?.link_1.href == '#' ? '' : '_blank'
              }
              cursor={'pointer'}
            >
              {item?.link_1.title}
            </Link>
          )}
          {item?.link_2?.title && (
            <Center height={4} display={'inline-block'} verticalAlign={'middle'}>
              <Divider orientation="vertical" />
            </Center>
          )}

          {item?.link_2 && (
            <Link
              aria-label={`Promotion link: ${item.link_2.title}`}
              _hover={{ color: '#ffffff' }}
              px={1}
              onClick={() => promoClickUtagLinkEvent(getPromoName(item?.link_2.href, '2'))}
              fontWeight={600}
              href={item?.link_2.href}
              textDecoration={'underline'}
              whiteSpace={'nowrap'}
              tabIndex={0}
              onFocus={handleFocus}
              onBlur={handleBlur}
              target={'_blank'}
              cursor={'pointer'}
            >
              {item?.link_2?.title}
            </Link>
          )}

          {item?.image && (
            <Image
              display={'inline-block'}
              src={item?.image.url}
              alt={item?.image.title ? `Image for ${item?.image.title}` : `Image for Banner accouncement`}
            />
          )}

          {item?.tooltip_content && (
            <Modal isOpen={onOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent borderRadius={0} mt={'7rem'} padding={[5, 4, 5, 6]} w={{ base: '100%', md: '512px' }}>
                <ModalCloseButton onClick={handleClick} />
                <ModalBody>
                  <Text color="light-100">{item?.tooltip_content}</Text>
                </ModalBody>
              </ModalContent>
            </Modal>
          )}
        </Box>
      ))}
    </Box>
  )
}
