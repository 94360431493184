import NextLink from 'next/link'
import { Box, BoxProps, Button, Image, useBreakpointValue, useTheme } from '@chakra-ui/react'
import { ContentstackBannerPromotion } from '@Types/contentstack'
import { extractPath } from 'composable/helpers/utils/extract-url'
import { promoClickUtagLinkEvent } from 'helpers/tealiumHelper'

const paddingRegex = /^((\d+(?:\.\d+)?(?:px|em|rem|%|vh|vw|vmin|vmax|ch|ex|cm|mm|in|pt|pc)?)\s?){1,4}$/

const parsePadding = (paddingString: string | undefined) => {
  if (!paddingString) return {}

  const match = paddingString.match(paddingRegex)
  if (!match) return {}

  const values = match[0].trim().split(/\s+/)

  switch (values.length) {
    case 4:
      return {
        paddingLeft: values[0],
        paddingRight: values[1],
        paddingTop: values[2],
        paddingBottom: values[3],
      }
    case 3:
      return {
        paddingLeft: values[0],
        paddingRight: values[1],
        paddingTop: values[2],
        paddingBottom: values[2],
      }
    case 2:
      return {
        paddingLeft: values[0],
        paddingRight: values[0],
        paddingTop: values[1],
        paddingBottom: values[1],
      }
    case 1:
      return {
        paddingLeft: values[0],
        paddingRight: values[0],
        paddingTop: values[0],
        paddingBottom: values[0],
      }
    default:
      return {}
  }
}

export const BannerPromotion = (props: ContentstackBannerPromotion) => {
  const {
    background_images_group: { background_image_desktop, background_image_mobile },
    styling_group: {
      configurable_padding_mobile,
      configurable_padding,
      container_margin_bottom,
      container_margin_top,
      theme,
      background_color,
      custom_background_color,
      content_alignment = 'left',
      container_size,
    },
    primary_call_to_action_group: { button_label, href },
    secondary_call_to_action_group: { secondary_button_label, secondary_href },
    analyticsTrackingData,
    relativeIndex,
    isMobileDevice,
  } = props

  const getTheme = useTheme()
  const analyticsRelativeIndex = relativeIndex ?? 1
  const isMobileViewport = useBreakpointValue({ base: true, lg: false }, { fallback: isMobileDevice ? 'base' : 'lg' })
  const isMobile = typeof window === 'undefined' ? isMobileDevice : isMobileViewport

  const minHeight: BoxProps['minHeight'] = ['110px']
  const image = isMobile ? background_image_mobile : background_image_desktop

  const containerBackgroundColor =
    background_color === 'custom' && custom_background_color
      ? custom_background_color
      : theme === 'light'
        ? 'white'
        : 'surface.inverse'

  const containerSize = container_size === 'full' ? '100%' : getTheme.breakpoints[container_size]

  const secondaryButtonProps = {
    variant: theme === 'light' ? 'outline' : 'outline-alt',
    backgroundColor: 'transparent',
    size: 'lg',
    href: secondary_href,
  }

  const primaryButtonProps = {
    variant: theme === 'light' ? 'solid' : 'solid-alt',
    size: 'lg',
    href,
  }

  const getDataPromotionName = (href, index) => {
    const analyticsLocationIdentifier = extractPath(href ?? '')
    const dataPromotionName =
      analyticsTrackingData && `${analyticsTrackingData} | ${index ?? 1} | ${analyticsLocationIdentifier}`
    return dataPromotionName
  }

  const paddingValues = configurable_padding ? parsePadding(configurable_padding) : parsePadding('0px')
  const paddingValuesMobile = configurable_padding_mobile
    ? parsePadding(configurable_padding_mobile)
    : parsePadding('0px')

  return (
    <Box
      data-promotion-name={getDataPromotionName(href, analyticsRelativeIndex)}
      minHeight={minHeight}
      maxWidth={containerSize}
      width="100%"
      position="relative"
      display="flex"
      flexDirection={isMobile ? 'column' : 'row'}
      alignItems="center"
      margin={'0 auto'}
      mt={!!container_margin_top ? container_margin_top : 0}
      mb={!!container_margin_bottom ? container_margin_bottom : 0}
      paddingTop={isMobile ? paddingValuesMobile.paddingTop : paddingValues.paddingTop}
      paddingRight={isMobile ? paddingValuesMobile.paddingRight : paddingValues.paddingRight}
      paddingBottom={isMobile ? paddingValuesMobile.paddingBottom : paddingValues.paddingBottom}
      paddingLeft={isMobile ? paddingValuesMobile.paddingLeft : paddingValues.paddingLeft}
      backgroundColor={containerBackgroundColor}
      {...(!button_label &&
        href && {
          as: NextLink,
          href,
          cursor: 'pointer',
        })}
    >
      <Box
        display="flex"
        flex={3}
        justifyContent={getContentAlignment(content_alignment)}
        {...(isMobile && { width: '100%' })}
      >
        <Image
          src={image?.url}
          alt={image?.description || image?.title || 'Banner Promotion Image'}
          style={{ height: '100%' }}
        />
      </Box>

      {(!!button_label || !!secondary_button_label) && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection={isMobile ? 'column' : 'row'}
          gap={{ base: 4 }}
          {...(!isMobile && { px: { base: 5 }, flexWrap: 'wrap' })}
          {...(isMobile && { pt: { base: 8 }, width: '100%' })}
        >
          {!!button_label && (
            <Button
              as={NextLink}
              href={href ?? ''}
              onClick={() => {
                promoClickUtagLinkEvent(getDataPromotionName(href, analyticsRelativeIndex))
              }}
              aria-label={`${button_label}`}
              style={{
                whiteSpace: 'normal',
                overflow: 'hidden',
                textOverflow: 'clip',
                width: isMobile ? '100%' : 'auto',
                textDecoration: 'none',
              }}
              {...primaryButtonProps}
            >
              {button_label}
            </Button>
          )}

          {!!secondary_button_label && (
            <Button
              as={NextLink}
              href={secondary_href ?? ''}
              onClick={() => {
                promoClickUtagLinkEvent(
                  getDataPromotionName(
                    secondary_href,
                    !!button_label ? analyticsRelativeIndex + 1 : analyticsRelativeIndex,
                  ),
                )
              }}
              aria-label={`${secondary_button_label}`}
              style={{
                whiteSpace: 'normal',
                overflow: 'hidden',
                textOverflow: 'clip',
                width: isMobile ? '100%' : 'auto',
                textDecoration: 'none',
              }}
              {...secondaryButtonProps}
            >
              {secondary_button_label}
            </Button>
          )}
        </Box>
      )}
    </Box>
  )
}

const getContentAlignment = (contentAlignment: string) => {
  if (contentAlignment === 'center') {
    return 'center'
  }

  if (contentAlignment === 'right') {
    return 'flex-end'
  }

  return 'left'
}
